import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import locales from "../utility/locales"


function Seo({ description, langCode, canonical, ogImage, meta, keywords, robots, skipEn, title = '' }) {

  const { site } = useStaticQuery(
    graphql`
      query DefaultSeoQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const lang = locales[langCode].lang;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          ogImage && ogImage.node ? [{
            property: `og:image`,
            content: ogImage.node.resize.src,
          }, {
            property: `twitter:image`,
            content: ogImage.node.resize.src,
          }]
        : [])
        .concat(ogImage && ogImage.node ? [{
          property: 'og:image:width',
          content: ogImage.node.resize.width
        }, {
          property: 'og:image:height',
          content: ogImage.node.resize.height
        }, {
          property: 'twitter:image:width',
          content: ogImage.node.resize.width
        }, {
          property: 'twitter:image:height',
          content: ogImage.node.resize.height
        }
        ] : [])
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(
          skipEn && langCode === 'en-US' ? 
            { name: 'robots', value: 'none' } 
          : (
            robots ? { name: 'robots', value: robots.join(', ') } : { name: 'robots', value: 'index,follow' }
          )
        )
        .concat(meta)}
      link={canonical ? [{
        rel: 'canonical',
        href: canonical
      }] : []}
    />
  )
}

Seo.defaultProps = {
  langCode: `it-IT`,
  meta: [],
  keywords: [],
}

Seo.propTypes = {
  description: PropTypes.string,
  langCode: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default Seo;
export const seoPaginaTitleFragment = graphql`fragment PaginainternaTitleFragment on LiferayPaginainternaHtmlTitle{
  content {
    metaDescription {
      value
    }
    metaRobots {
      value
    }
    canonicalUrl {
      value
    }
    ogImage {
      node {
        resize(width: 1200) {
          src
          width
          height
        }
      }
    }
  }
}`;
export const seoPrivacyTitleFragment = graphql`fragment PrivacyTitleFragment on LiferayPaginaprivacyHtmlTitle{
  content {
    metaDescription {
      value
    }
    canonicalUrl {
      value
    }
    metaRobots {
      value
    }
    ogImage {
      node {
        resize(width: 1200) {
          src
          width
          height
        }
      }
    }
  }
}`;