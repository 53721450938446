import React from 'react'
import useDarkMode from 'use-dark-mode'


const ThemeSwitch = ({ className }) => {
  const darkMode = useDarkMode(false);

  return (
    <div className="theme-switch">
      <div className={`nj-switch nj-switch--alternate${className ? ` ${className}` : ``}`}>
        <label className="mb-0">
          <input type="checkbox" checked={darkMode.value} onChange={darkMode.toggle} />
          <span className="nj-switch__slider">
            <span className="nj-switch__slider-left px-0">
              <i className="nj-icon nj-icon-sun nj-icon--white"></i>
            </span>
            <span className="nj-switch__slider-right px-0">
              <i className="nj-icon nj-icon-nature nj-icon--white"></i>
            </span>
          </span>
        </label>
      </div>
    </div>
  );
}

export default ThemeSwitch;
