import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from './link'
import IntlWrapper from './intl-wrapper'
import Header from './header'
import Footer from './footer'
import AppContext from '../context/app-context'
import useScroll from '../utility/useScroll'


const Layout = ({ langCode, tags, children, location }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const dataTags = useMemo(() => tags && tags.length ? tags.map(item => item.taxonomyCategoryName) : [], [tags]);

  const scrolled = useScroll(200);

  const { darkMode } = useContext(AppContext);
  const mailBar = false;

  return (
    <IntlWrapper locale={langCode}>
      <div className={`wrapper${scrolled ? ' wrapper--scrolled' : ''}${darkMode ? ' wrapper--dark' : ''}`}>
        <Header siteTitle={site.siteMetadata.title} langCode={langCode} location={location} />
        {dataTags.map(tag => <span className="tag-argument d-none" key={tag}>{tag}</span>)}
        {mailBar &&
          <Link to="/" className="lateral-bar d-none d-lg-block">
            <i className="nj-icon nj-icon-envelope nj-icon--circle size-60"></i>
          </Link>
        }
        {children}
        <Footer langCode={langCode} />
      </div>
    </IntlWrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;