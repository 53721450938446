import React, { useMemo, forwardRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from './link'
import ChevronRightIcon from '../images/icons/material/chevron_right.svg'
import ExpandMoreIcon from '../images/icons/material/expand_more.svg'


const SitesSelect = forwardRef(({ open, onClick, langCode }, ref) => {

  const { allMenuCorporate } = useStaticQuery(
    graphql`
      query {
        allMenuCorporate: allLiferayMenuCorporate{
          edges {
            node {
              langCode
              pageTitle {
                value
                content {
                  icon {
                    value
                  }
                  description {
                    value
                  }
                  link {
                    value
                  }
                }
              }
            }
          }
        }       
      }
    `
  );
  const menuCorporate = useMemo(() => {
    const edge = allMenuCorporate.edges.find(edge => edge.node.langCode === langCode);
    return edge && edge.node;
  }, [allMenuCorporate, langCode]);

  return (
    <button className={`sites-select${open ? ' sites-select--open' : ''} d-inline-flex align-items-center px-2 mr-1`} 
      onClick={onClick} ref={ref}>
      <i className="nj-icon nj-icon-family-1 size-40 text-white"></i>
      <span className="text-white font-weight-bold d-inline-block">Enti</span>
      <ExpandMoreIcon className="sites-select__toggle material-svg text-white ml-auto" />
      <div className={`w-100 d-flex flex-column sites-select__menu${open ? ' sites-select__menu--open' : ''}`} >
        <div className="container flex-fill">
          <ul className="row sites-select__list px-0">
            {menuCorporate.pageTitle.map((item, i) => (
              <li key={i} className="sites-select__menuitem col-12 col-xl-3 px-2 py-2">
                <Link to={item.content.link.value} className="sites-select__link d-flex flex-xl-column align-items-center align-items-xl-start">
                  <div className="w-100 text-left">
                    <span className="d-block font-weight-bold mb-1">
                      {item.value}
                    </span>
                    <p className="sites-select__description">
                      {item.content.description.value}
                    </p>
                  </div>
                  <ChevronRightIcon className="material-svg text-white ml-auto ml-xl-0" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </button>
  );
});

export default SitesSelect;
