import React, { useCallback, useState } from 'react'
import useClickOut from '../../utility/useClickOut'


const FooterSites = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(value => !value), []);
  const ref = useClickOut(open, setOpen);
  
  return (
    <div ref={ref} className={`footer-sites ${open ? 'footer-sites--open ':''}d-inline-block`}>
      <button className="footer-sites__btn text-uppercase text-blue-corporate font-weight-bold" onClick={toggleOpen}>
        Enti
      </button>
      <div className="footer-sites__content text-uppercase">
        <a className="footer-sites__link" href="https://www.engie.it/">Corporate</a>
        <a className="footer-sites__link" href="https://imprese.engie.it/">Imprese</a>
        <a className="footer-sites__link" href="https://imprese.engie.it/condomini/">Condomini</a>
        <a className="footer-sites__link" href="https://casa.engie.it/">Casa</a>
      </div>
    </div>
  );
}

export default FooterSites;
