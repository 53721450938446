import PropTypes from 'prop-types'
import React, { useState, useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import logo from '../images/logo-engie-blue.svg'
import logoCompact from '../images/logo-engie-small-blue.svg'
import { Link } from './link'
import ThemeSwitch from './elements/theme-switch'
import useBodyClass from '../utility/useBodyClass'
import useClickOut from '../utility/useClickOut'
import SitesSelect from './sites-select'
import Navigation from './navigation'
import AppContext from '../context/app-context'
import PreviewBar from './preview-bar'


const Header = ({ siteTitle, langCode, location }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen(value => !value), []);
  const [selectOpen, setSelectOpen] = useState(false);
  const toggleSelect = useCallback(() => setSelectOpen(value => !value), []);

  useBodyClass(menuOpen || selectOpen, 'block-scroll-mobile');
  // useBodyClass(searchOpen, 'block-scroll-modal');

  const menuRef = useClickOut(menuOpen, setMenuOpen);
  const selectRef = useClickOut(selectOpen, setSelectOpen);
  // const searchRef = useClickOut(searchOpen, setSearchOpen);

  const { darkMode, setDarkMode } = useContext(AppContext);

  return (
    <div className="header">
      <div className="nj-header nj-header--fixed">
        <div className="nj-header__group">
          {process.env.NODE_ENV === 'development' &&
            <PreviewBar />
          }
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header__top d-flex align-items-center justify-content-between">
                  <div className="d-inline-flex align-items-center">
                    <ThemeSwitch checked={darkMode} setChecked={setDarkMode} />
                  </div>
                  <div className="header__logo">
                    <Link className="header__link h-100" to="/">
                      <img src={logo} className="h-100" alt={siteTitle} />
                    </Link>
                  </div>
                  <div className="d-inline-flex align-items-center">
                    <Link to="/contatti/" className="header__link d-none d-xl-inline-block mr-1">
                      <FormattedMessage id="contatti" />
                    </Link>
                    {/* <Link to="/faq/" className="header__link d-none d-xl-inline-block mr-1">FAQ</Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 position-static">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-inline-flex align-items-center w-100">
                    <div className="header__logo-compact mr-2">
                      <Link className="header__link w-100" to="/">
                        <img src={logoCompact} className="w-100" alt={siteTitle} />
                      </Link>
                    </div>
                    <SitesSelect open={selectOpen} onClick={toggleSelect} langCode={langCode} ref={selectRef} />
                  </div>
                  <Navigation open={menuOpen} ref={menuRef} langCode={langCode} location={location} />
                  <div className="header__buttons d-inline-flex align-items-center ml-auto">
                    <button className={`hamburger d-xl-none${menuOpen ? ' open' : ''}`} onClick={toggleMenu}>
                      <span></span><span></span><span></span><span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
