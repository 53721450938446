import { useStaticQuery, graphql } from 'gatsby'
import React, { forwardRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from './link'
// import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import useClickOut from '../utility/useClickOut'
import pageUrl from "../utility/page-url"
import ChevronRightIcon from '../images/icons/material/chevron_right.svg'
import ChevronLeftIcon from '../images/icons/material/chevron_left.svg'
import ExpandMoreIcon from '../images/icons/material/expand_more.svg'
import LightbulbOutlineIcon from '../images/icons/material/lightbulb_outline.svg'


const isActive = (page, location) => {
  // console.log('isActive: ', page, location);
  if (!location) return false;
  if (`/${pageUrl(page)}` === location.pathname) return true;
  const checkChildren = (page, location) => {
    if (!page.pageChildren) return false;
    return !!page.pageChildren.find(item => pageLink(item) === location.pathname ||
      checkChildren(item, location));
  };
  return checkChildren(page, location);
}

const pageLink = page => page.externalLink ? page.externalLink.value : `/${pageUrl(page)}`;

const Navigation = forwardRef(({ open, langCode = 'it-IT', location }, ref) => {
  // console.log('location: ', location);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [openItem, setOpenItem] = useState(null);

  const itemClick = (e, item) => {
    if (item.pageChildren) {
      e.preventDefault();
      setOpenItem(item);
      setSubmenuOpen(openItem !== item || !submenuOpen);
    }
  };

  useEffect(() => {
    if (!open) {
      setSubmenuOpen(false);
    }
  }, [open]);

  const { menuAll } = useStaticQuery(
    graphql`
      query {
        menuAll: allLiferayMenuPrincipale {
          edges{
            node {
              langCode
              pages {
                liferayId
                langCode
                pageTitle {
                  value
                  content {
                    friendlyUrl{
                      value
                    }
                  }
                }
                pageChildren {
                  ... on LiferayPaginainterna {
                    liferayId
                    langCode
                    pageTitle {
                      value
                      content {
                        friendlyUrl{
                          value
                        }
                        menuDescription {
                          value
                        }
                      }
                    }
                    pageChildren {
                      ... on LiferayPaginainterna {
                        langCode
                        pageTitle {
                          value
                          content {
                            friendlyUrl{
                              value
                            }
                          }
                        }
                        pageChildren {
                          ... on LiferayPaginainterna {
                            langCode
                            pageTitle {
                              value
                              content {
                                friendlyUrl{
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on LiferayPaginalink {
                    liferayId
                    langCode
                    pageTitle {
                      value
                      content {
                        menuDescription {
                          value
                        }
                      }
                    }
                    externalLink {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const menu = menuAll.edges.find(edge => edge.node.langCode === langCode).node;

  const subMenuRef = useClickOut(submenuOpen, setSubmenuOpen);
  // settare su true per visualizzare la variante con il banner a destra del menu
  const banner = false;
  // settare su true per visualizzare la variante con icona della lampadina accanto voci di menu
  const bulbIcon= false;

  return (
    <div ref={ref} className={`navigation w-100 w-xl-auto${open ? ' navigation--open' : ''}${submenuOpen ? ' navigation--subopen' : ''}`}>
      <div className="navigation__container" ref={subMenuRef}>
        <ul className="navigation__list d-flex flex-column flex-xl-row align-items-center justify-content-between">
          {menu.pages.map((item, i) => (
            <li className={`navigation__item${isActive(item, location) ? ' navigation__item--active': ''} w-100 w-xl-auto${item === openItem? ' open' : ''}`} key={item.liferayId}>
              <div className="d-flex d-xl-inline-flex align-items-center">
                <Link className={`navigation__link first-level`} 
                  to={`/${pageUrl(item)}`}>
                  <span className={`${item.pageChildren ? ' font-weight-bold' : ''}`}>
                    {item.pageTitle.value}
                  </span>
                </Link>
                {item.pageChildren && (
                  <button className="navigation__toggle ml-auto d-xl-none" onClick={e => itemClick(e, item)}>
                    <ChevronRightIcon className="material-svg text-blue-corporate" />
                  </button>
                )}
                {item.pageChildren && (
                  <button className={`navigation__toggle d-none d-xl-inline-flex${item === openItem && submenuOpen ? ' navigation__toggle--open' : ''}`}
                    onClick={e => itemClick(e, item)}>
                    <ExpandMoreIcon className="material-svg" />
                  </button>
                )}
              </div>
              {item.pageChildren &&
                <div className={`d-flex flex-column navigation__submenu${openItem === item && submenuOpen ? ' navigation__submenu--open' : ''}${banner ? ' navigation__submenu--banner' : ''}`} >
                  <button className="navigation__subbutton px-2 py-2 d-flex align-items-center d-xl-none" onClick={() => setSubmenuOpen(false)}>
                    <ChevronLeftIcon className="material-svg text-blue-corporate d-xl-none" />
                    <span className="text-blue-corporate font-weight-bold">{openItem && openItem.name}</span>
                  </button>
                  <div className={`${banner ? 'navigation__wrapper' : ''}`}>
                    <div className="container flex-fill">
                      <div className="row">
                        <div className={`${banner ? 'col-xl-9' : 'col-12'}`}>
                          <ul className="row navigation__list px-0">
                            {item.pageChildren && item.pageChildren.map((child, j) => (
                              <li key={child.liferayId} className={`navigation__subitem col-12 ${banner ? 'col-xl-4' : 'col-xl-3 '} px-2 py-2`}>
                                <Link to={pageLink(child)} className="navigation__link d-flex align-items-start">
                                {bulbIcon && (
                                  <LightbulbOutlineIcon className="material-svg text-blue-corporate mr-1" />
                                )}
                                  <div className="w-100 d-flex flex-xl-column align-items-center align-items-xl-start">
                                    <div className="">
                                      <span className="d-block font-weight-bold">
                                        {child.pageTitle.value}
                                      </span>
                                      {child.pageTitle.content.menuDescription ? (
                                        <p className="navigation__description mt-1">
                                          {child.pageTitle.content.menuDescription.value}
                                        </p>
                                      ): null}
                                    </div>
                                    <ChevronRightIcon className="material-svg arrow ml-auto ml-xl-0 mt-0 mt-xl-2" />
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* banner di destra */}
                  {/* {banner && (
                    <div className="banner">
                      <Link to="/page-2/" className="banner__box h-100 p-4 p-md-6">
                        <Img image={getImage(background.childImageSharp)} alt="" className="banner__img" />
                        <div className="banner__gradient-box position-relative text-white h-100 d-flex flex-column justify-content-end">
                          <div className="banner__title">
                            <span className="d-lg-block">Clima </span>
                            peso Zero
                          </div>
                          <div className="banner__subtitle mt-2 mb-4">Recuperi il 50% grazie alle detrazioni fiscali.</div>
                          <div className="d-flex align-items-center">
                            <div className="banner__link border-bottom border-white">
                              Scopri di più
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  */}
                </div>
              }
            </li>
          ))}
        </ul>
        <div className="d-xl-none">
          <div className="mt-3 mb-1">
            <Link to="/contatti/" className="header__link px-2"><FormattedMessage id="contatti" /></Link>
          </div>
          {/* <div className="mb-1">
            <Link to="/faq/" className="header__link px-2">FAQ</Link>
          </div> */}
        </div>
      </div>
    </div>
  );
});

export default Navigation;